import $ from 'jquery'

const $doc = $(document)


function capitalizeAndClean(input) {
  const words = input.split(/[^a-zA-Z0-9]+/);
  const capitalizedWords = words.map(word => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return '';
  });

  const result = capitalizedWords.join(' ');
  return result;
}

function filteringAjax(element) {
  var category = $(element).data('cat');
  if (category === 'all') {
    category = '';
  }
  var keyword = $('#library-keyword').val();
  var adminAjaxUrl = $('#adminAjaxUrl').val();
  console.log('Category: ' + category);
  console.log('Search: ' + keyword);
  var cleanCategory = capitalizeAndClean(category);

  var filter_data = {
    category: category,
    keyword: keyword,
    action: 'filtering_library'
  }
  $.ajax({
    url: adminAjaxUrl,
    data: filter_data,
    type: 'POST',
    beforeSend: function () {
      $('.js-library-loadmore').remove();
      $('#ajax-loading').show();
    },
    success: function (data) {
      $('#ajax-loading').hide();
      $(".js-library-grid").html(data);
      // $(".library__breadcrumbs").show();
      // $(".library__breadcrumbs").html("You're searching for: <span>" + cleanCategory + "</span>");
    }
  });
}

function fetchPreviewPost(element) {
  var categorySlug = $(element).data('cat');
  var adminAjaxUrl = $('#adminAjaxUrl').val();

  // console.log('Fetching preview post for category: ' + categorySlug);
  var requestData = {
    action: 'fetch_single_post_preview',
    category_slug: categorySlug
  };
  $.ajax({
    url: adminAjaxUrl,
    data: requestData,
    type: 'POST',
    beforeSend: function () {
      $('.js-preview-post').html('<p>Loading...</p>');
    },
    success: function (response) {
      $('.js-preview-post').html(response);
    },
    error: function () {
      $('.js-preview-post').html('<p>Error loading post.</p>');
    }
  });
}

function hideSubcategories() {
  $('.nav--library__dropdown__subcategories').css('display', 'none');
  $('.nav--library__dropdown').css('display', 'none');
  $(".nav--library__dropdown h2").text('');
}


export default function initLibrary() {
  $doc.on('click', '.js-library-cat', function () {
    filteringAjax($(this));
  });

  $doc.on('click', '.js-library-subcat', function () {
    hideSubcategories();
    filteringAjax($(this));
    $(".js-library-cat").removeClass('is-active');
    var parentCat = $(this).closest('.nav--library__dropdown__subcategories').attr('parentslug');
    $(".js-library-cat[data-cat='" + parentCat + "']").addClass('is-active');
  });

  $doc.on('click', '#library-keyword', function (event) {
    $('#library-keyword').focus();
    $('#library-keyword').select();
  });

  $('#library-keyword').keypress(function (event) {
    if (event.keyCode === 10 || event.keyCode === 13) {
      event.preventDefault();
    }
  });

  $('.js-library-subcat').hover(function () {
    fetchPreviewPost($(this));
  }, function () {
    $('#js-preview-post').html('');
  });

  $('.js-library-cat').on('click', function (event) {
    $('.js-library-cat').removeClass('is-active');
    $(this).addClass('is-active');
    filteringAjax;
  });

  $('#library-keyword').keyup(filteringAjax);

  var isHoveringDropdown = false; // Flag to track if hovering over the dropdown
  $('.js-library-cat').hover(function (e) {
    var elementSlug = $(this).attr('elementslug');
    var elementName = $(this).attr('elementname');
    var matchingElement = $('.nav--library__dropdown__subcategories').filter(function () {
      return $(this).attr('parentslug') === elementSlug;
    });

    if (matchingElement.length != 0) {
      $(".nav--library__dropdown h2").text(elementName);
      matchingElement.css('display', 'block');
      $('.nav--library__dropdown').css('display', 'block');
    }
  }, function () {
    // Delay hiding to check if user is hovering over the dropdown
    setTimeout(function () {
      if (!isHoveringDropdown) {
        $('.nav--library__dropdown__subcategories').css('display', 'none');
        $('.nav--library__dropdown').css('display', 'none');
        $(".nav--library__dropdown h2").text('');
      }
    }, 10);
  });

  // Mouseenter and mouseleave for the dropdown
  $('.nav--library__dropdown').hover(function () {
    isHoveringDropdown = true; // Set flag when hovering over the dropdown
  }, function () {
    isHoveringDropdown = false; // Clear flag when not hovering over the dropdown
    $('.nav--library__dropdown__subcategories').css('display', 'none');
    $(this).css('display', 'none');
    $(".nav--library__dropdown h2").text('');
  });

  $('.js-nav-library-dropdown-close').on('click', function () {
    hideSubcategories();
  })
}
