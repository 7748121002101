import $ from 'jquery'
const $doc = $(document)

function toggleAccordions(event) {
  let accordions = document.querySelectorAll('.js-toggle-accordion');
  let accordionContents = document.querySelectorAll('.member__mobile-tab');
  let length = accordions.length;

  accordions.forEach(function (accordion, index) {
    accordion.onclick = () => {
      console.log("click");
      accordionContents.forEach(function(c, i) {
        c.classList[index === i ? "toggle" : "remove"]('tab-visible');
      });
    };
  });
}

function expandDetails(event) {
  event.preventDefault()
  if($(event.currentTarget).closest('div').hasClass('is-active')) {
    $(event.currentTarget).closest('div').removeClass('is-active')
    $(event.currentTarget).text('Show Details')
  } else {
    $(event.currentTarget).closest('div').addClass('is-active')
    $(event.currentTarget).text('Hide Details')
  }
  console.log("click Details");
}

export default function initTeamAccordions(){
  $doc.on('ready', toggleAccordions )
  $doc.on('click', '.js-expand-team', expandDetails )
}
