import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

function animateSimple(elem) {
  gsap.to(elem, {
    duration: 0.5,
    y: 0,
    autoAlpha: 1,
    ease: "power1.out",
    stagger: 0.2,
    delay: 0.3,
    overwrite: 'auto'
  })
}

function setupFadeInElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() { animateSimple(elem) }
  })
}

function animateFade(elem) {
  gsap.to(elem, {
    duration: 0.5,
    autoAlpha: 1,
    ease: "power1.out",
    delay: 0.2,
    overwrite: 'auto'
  })
}

function setupFadeElement(elem) {
  hideFade(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() { animateFade(elem) }
  })
}

function setupParallaxElement(elem) {
  gsap.to(elem, {
    scrollTrigger: {
      trigger: $(elem).parent(),
      start: "top 90%",
      end: "bottom 15%",
      scrub: true,
      // markers: true
    },
    top: 0,
    ease: "linear",
    duration: 3
  })
}

function mobileAnimation() {
  console.log('enter anim');
  let scene1mobile = gsap.timeline({delay:1});
  let speed = 100;
  // ScrollTrigger.create({
  //   toggleActions: "restart pause restart pause",
  //   scroller: ".js-approach-slider",
  //   animation: scene1mobile,
  //   pin: ".approach__anim-slide-pin",
  //   trigger: ".approach__anim-slide-mobile",
  //   // markers: true,
  //   start: "top 30px",
  //   end: "bottom 100%",
  //   // scrub: true,
  // });
  scene1mobile.to(".letters-group--mob .letter-img-wrap", { opacity: 1, x: '5vh', ease: "power1.in",duration: 0.8 }, 0)
              .to(".content-group--mob .content-group__title", { opacity: 1, ease: "power1.in",duration: 0.8 }, 0)
              .to(".content-group--mob .content-group__title", { color: '#00CEEA', ease: "power1.in", duration: 0.5 }, 1.2)
              .to(".content-group--mob .content-group__subtitle", { maxHeight: '100%', opacity: 1, ease: "power1.in", duration: 0.5 }, 1.2)
              .to(".letters-group--mob .letter-img-wrap", { x: '-15vh', ease: "power1.in",duration: 1 }, 1.2)
              .to(".image-group--mob .image-group__img", { x: '-10vh', opacity: 1, ease: "power1.in",duration: 1 }, 1.2)
              .to(".letters-group--mob", { opacity: 1, ease: "power1.in",duration: 2 }, 0.8);
}

function desktopAnimation() {
  let scene1desktop = gsap.timeline({delay:1});
  let speed = 100;
  // ScrollTrigger.create({
  //   toggleActions: "restart pause restart pause",
  //   scroller: ".js-approach-slider",
  //   animation: scene1desktop,
  //   pin: ".approach__anim-slide-pin",
  //   trigger: ".approach__anim-slide-desktop",
  //   // markers: true,
  //   start: "top top",
  //   end: "bottom bottom",
  //   // onUpdate: self => {
  //   //   console.log('Progress: ' + self.progress);
  //   // }
  //   // scrub: 1,
  // });
  scene1desktop.to(".letters-group--desk .letter-img-wrap.letter-a", { opacity: 1, x: '-17vw', ease: "power1.in",duration: 0.5 }, 0)
               .to(".letters-group--desk .letter-img-wrap.letter-c", { opacity: 1, x: '-6vw', ease: "power1.in",duration: 0.5 }, 0)
               .to(".letters-group--desk .letter-img-wrap.letter-t", { opacity: 1, x: '3vw', ease: "power1.in",duration: 0.5 }, 0)
               .to(".image-group--desk .image-group__img", { y: -0.6 * speed, ease: "power1.in", duration: 0.5 }, 0)
               .to(".content-group--desk .title-a", { opacity: 1, x: '-6.5vw', ease: "power1.in",duration: 0.5 }, 0.3)
               .to(".content-group--desk .title-c", { opacity: 1, x: '-4vw',  ease: "power1.in",duration: 0.5 }, 0.3)
               .to(".content-group--desk .title-t", { opacity: 1, x: '-1vw',  ease: "power1.in",duration: 0.5 }, 0.3)
               .to(".letters-group--desk .letter-img-wrap.letter-a", { left: '-7vw', x: 0, y: -2 * speed, ease: "power1.in",duration: 0.8 }, 1.8)
               .to(".letters-group--desk .letter-img-wrap.letter-c", { left: '-7vw', x: 0, ease: "power1.in",duration: 0.8 }, 1.8)
               .to(".letters-group--desk .letter-img-wrap.letter-t", { left: '-7vw', x: 0, y: 2 * speed, ease: "power1.in",duration: 0.8 }, 1.8)
               .to(".content-group--desk .title-a", { x: 0, ease: "power1.in",duration: 0.3 }, 1.8)
               .to(".content-group--desk .title-c", { x: 0, ease: "power1.in",duration: 0.3 }, 1.8)
               .to(".content-group--desk .title-t", { x: 0, ease: "power1.in",duration: 0.3 }, 1.8)
               .to(".content-group--desk .content-group__meta", { y: 0.6 * speed, ease: "power1.in",duration: 0.3 }, 1.8)
               .to(".content-group--desk .content-group__title", { color: '#00CEEA', ease: "power1.in", duration: 0.5 }, 1.8)
               .to(".content-group--desk .content-group__subtitle", { opacity: 1, maxHeight: '100%', ease: "power1.in", duration: 0.5 }, 1.8)
               .to(".image-group--desk .image-group__img", { opacity: 1, ease: "power1.in", duration: 0.5 }, 2)
               .to(".image-group--desk", { opacity: 1, ease: "power1.in", duration: 1 }, 2.1);
}

function tweenProperty(target, prop, min, max) {
  var randomDur = gsap.utils.random(3, 6, 0.2, true);
  var randomDelay = gsap.utils.random(0.6, 2, 0.2, true);

  gsap.to(target,  {
    [prop]: gsap.utils.random(min, max),
    duration: randomDur(),
    delay: randomDelay(),
    ease: 'linear',
    force3D: true,
    onComplete: tweenProperty,
    onCompleteParams: [target, prop, min, max]
  });
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0, y: 40})
}

function hideFade(elem) {
  gsap.set(elem, {autoAlpha: 0})
}

function init(){
  gsap.registerPlugin(ScrollTrigger)
  gsap.utils.toArray(".js-fadein").forEach( setupFadeInElement )
  gsap.utils.toArray(".js-fade").forEach( setupFadeElement )
  gsap.utils.toArray(".js-parallax").forEach( setupParallaxElement )

  //Flovy animation for desktop devices
  $(window).on("load resize",function(e){
    if (window.innerWidth > 959) {
      gsap.utils.toArray(".bubble").forEach(star => {
        tweenProperty(star, "scale", .9, 1.1);
        tweenProperty(star, "x", -15, 15);
        tweenProperty(star, "y", -8, 8);
      });
    }
  });

  //Approach page scroll animation, added here for throwing an error if separated in a file.
  if($('body').hasClass('page-template-approach')) {
    $(window).on("load resize",function(e){
      if($(window).width() > 959) {
        desktopAnimation();
      } else {
        mobileAnimation();
      }
    });
  }
}

export default function initGreenSock(){
  $(document).ready( init )
}
