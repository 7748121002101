import $ from 'jquery'
import slick from 'slick-carousel'

export default function initSlick(){
  const settings_work_samples = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    centerMode: true,
    centerPadding: '200px',
    infinite: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          centerMode: false,
          centerPadding: '0',
        }
      }
    ]
  };

  const settings_testimonials = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    mobileFirst: true,
    infinite: false,
    fade: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          dots: false,
          arrows: true
        }
      }
    ]
  };

  const settings_default = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    mobileFirst: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  const settings_singlePost = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    mobileFirst: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  const settings_tabs_main = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    infinite: false,
    asNavFor: '.js-infotabs-nav-slider',
    responsive: [
      {
        breakpoint: 961,
        settings: {
          arrows: false,
          dots: true
        }
      },
    ]
  };

  const settings_tabs_nav = {
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.js-infotabs-main-slider',
    dots: true,
    focusOnSelect: true,
    vertical: true,
    infinite: false,
  };

  const settings_quotes = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    dots: false,
    arrows: false,
    buttons: false,
    infinite: true,
    centerMode: true,
    centerPadding: '200px',
    // variableWidth: true,
    draggable: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1460,
        settings: {
          dots: false,
          slidesToShow: 2,
          arrows: true
        }
      },
      {
        breakpoint: 499,
        settings: {
          dots: false,
          slidesToShow: 1,
          arrows: true,
          centerPadding: '300px',
        }
      },
    ]
  };

  const settings_job_posts = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    mobileFirst: true,
    infinite: false,
    cssEase: 'linear',
    touchThreshold:100,
    responsive: [
      {
        breakpoint: 1379,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 759,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  const settings_stickynav = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    mobileFirst: true,
    infinite: false,
    // adaptiveHeight: true,
    
    responsive: [
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 701,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1181,
        settings: {
          slidesToShow: 4,
          variableWidth: true,
        }
      }
    ]
  };

  const settings_librarynav = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    mobileFirst: true,
    infinite: false,
    // adaptiveHeight: true,
    
    responsive: [
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 701,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1181,
        settings: {
          slidesToShow: 5,
          variableWidth: true,
        }
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 6,
          variableWidth: true,
        }
      }
    ]
  };


  const testimonialsSlider =  $('.js-testimonials-slider').slick(settings_testimonials);
  const tabsMainSlider =  $('.js-infotabs-main-slider').slick(settings_tabs_main);
  $('.js-infotabs-main-slider').on('afterChange', function(event, slick, currentSlide, slideCount){
    if(currentSlide === (slick.slideCount - 1)) {
      $('.js-tab-btn-desk').addClass('is-active');
    } else {
      $('.js-tab-btn-desk').removeClass('is-active');
    }
  });
  const tabsNavSlider =  $('.js-infotabs-nav-slider').slick(settings_tabs_nav);
  const defaultSlider =  $('.js-posts-slider').slick(settings_default);
  const singlePostSlider =  $('.js-single-posts-slider').slick(settings_singlePost);
  const workSamples =  $('.js-work-samples').slick(settings_work_samples);
  const quotesSlider = $('.js-quote-slider').slick(settings_quotes).on("beforeChange", function(e, slick) {
      var width = $('.js-quote-slider .slick-current').outerWidth();
      var speed = (width*4000)/200;
      // console.log(speed);
      $('.js-quote-slider').slick("setOption", "speed", speed);
  });
  const jobPostsSlider = $('.js-job-posts-slider').slick(settings_job_posts);
  const stickynavSlider =  $('.js-nav-sticky').slick(settings_stickynav);
  const librarynavSlider =  $('.js-nav-library').slick(settings_librarynav);


  setTimeout(function(){
    $('.js-testimonials-slider.slick-initialized').addClass('slick-loaded');
    $('.js-posts-slider.slick-initialized').addClass('slick-loaded');
    $('.js-single-posts-slider.slick-initialized').addClass('slick-loaded');
    $('.js-work-samples.slick-initialized').addClass('slick-loaded');
    $('.js-infotabs-nav-slider.slick-initialized').addClass('slick-loaded');
    $('.js-infotabs-main-slider.slick-initialized').addClass('slick-loaded');
    $('.js-quote-slider.slick-initialized').addClass('slick-loaded');
    $('.js-job-posts-slider.slick-initialized').addClass('slick-loaded');
    $('.js-nav-sticky.slick-initialized').addClass('slick-loaded');
    $('.js-nav-library.slick-initialized').addClass('slick-loaded');
  }, 200);
}
