import $ from 'jquery'

const $doc = $(document)
const $header = $('.js-header')
const $stickyNav = $('.js-nav-sticky')


export default function initStickyNav(){
  if ( $stickyNav.length ) {

    var top = $stickyNav.offset().top;

    $(window).on("scroll resize",function(event){
      var y = $(this).scrollTop();
      if (y >= top) {
        $stickyNav.addClass('fixed');
        if($header.hasClass('headroom--pinned') && $header.hasClass('headroom--not-top')) {
          $stickyNav.addClass('header-visible');
        } else {
          $stickyNav.removeClass('header-visible');
        }
      } else {
        $stickyNav.removeClass('fixed').removeClass('header-visible');
      }
      $stickyNav.width($stickyNav.parent().width());
    });
  }
}
