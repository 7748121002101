import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'

import $ from 'jquery'
import initGreenSock  from '/lib/greensock'
import initHeadroom   from '/lib/headroom'
import initMobileNav  from '/lib/mobile-nav'
import initCookies    from '/lib/cookies'
import initScrollTo   from '/lib/scroll-to'
import initSlick      from '/lib/sliders'
import initMagnificPopup from '/lib/magnificPopup'
import initLibrary from './lib/library'
import initTeamAccordions from './lib/team-accordions'
import initStickyNav  from '/lib/sticky-nav'


initMagnificPopup()
initGreenSock()
initHeadroom()
initMobileNav()
initCookies()
initScrollTo()
initSlick()
initLibrary()
initTeamAccordions()
initStickyNav()
