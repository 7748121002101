import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

function resizeFunc(){
  $( window ).resize(function() {
    if($('html').hasClass('mfp-open')){
      $('header').addClass('headroom--pinned').removeClass('headroom--unpinned');
    }
  });
}

function closePopup() {
  $('.mfp-close').trigger('click');
}

function fillJobForm(jobtitle, jobsubtitle, formTitle, formText){
  $(".popup-form--text-form__left__title").text(jobtitle);
  $(".popup-form--text-form__left__text").text(jobsubtitle);
  $(".popup-form--text-form__title").text(formTitle);
  $(".popup-form--text-form__subtitle").text(formText);
  $(".mfp-content .jobTitle input").val(jobtitle);
}

function openModal(popupId) {
  let modalId = '#'+popupId;
  $.magnificPopup.open({
    items: {
      src: modalId,
      type: 'inline',
    },
    callbacks: {
      open: function() {
        $.magnificPopup.instance.close = function() {
          $('html').removeClass('mfp-open mfp-offset mfp-full');
          // Call the original close method to close the popup
          $.magnificPopup.proto.close.call(this);
        };
      }
    },
    closeBtnInside: false
  });
}

function openVideoModal(event) {
  event.preventDefault();
  var videoItem = $(event.currentTarget);
  var videoSrc = videoItem.data('video');
  $('header').addClass('headroom--pinned').removeClass('headroom--unpinned');
  $('html').addClass('mfp-offset').addClass('mfp-open');

  $.magnificPopup.open({
    items: {
      src: videoSrc,
      type: 'iframe',
    },
    midClick: true,
    removalDelay: 100,
    mainClass: 'popup--animate',
    iframe: {
      markup: '<div class="mfp-video-header">' +
        '<div class="mfp-close"></div>' +
        '</div>' +
        '<div class="mfp-iframe-scaler">' +
        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
        '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
      patterns: {
        youtube: {
          index: 'youtube.com',
          id: 'v=',
          src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
        }
      }
    },
    callbacks: {
      open: function() {
        $.magnificPopup.instance.close = function() {
          $('html').removeClass('mfp-open mfp-offset');
          // Call the original close method to close the popup
          $.magnificPopup.proto.close.call(this);
        };
      }
    },
    closeBtnInside: false
  });
}

export default function initMagnificPopup(){
  $(document).ready(function(){
    $('.js-open-modal').on('click', function(){
      let popupId = $(this).attr('modalId');
      openModal(popupId)
      $('html').addClass('mfp-open');

      if($(this).attr('modaltype') != 'jobForm'){
        $('header').addClass('headroom--pinned').removeClass('headroom--unpinned');
        $('html').addClass('mfp-offset');
      }
      else{
        var formTitle= $(this).parent().find(".formPostTitle").text();
        var formText= $(this).parent().find(".formPostSubtitle").text();
        var jobtitle = $(this).parent().find(".leftTextTitle").text();
        var jobsubtitle = $(this).parent().find(".leftTextDesc").text();
        fillJobForm(jobtitle, jobsubtitle, formTitle, formText);
        $('html').addClass('mfp-full');
      }
    });

    $doc.on('click', '.js-video-popup', openVideoModal )

    $(".popup-form__close").on('click', function(){
      closePopup();
    });
    $(".popup-form--text-form__arrow").on('click', function(){
      closePopup();
    });
    resizeFunc();
  })
}
